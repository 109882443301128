<template>
  <div class="history_video_warp">
    <PullRefresh
      :loading="loading"
      :refreshing="refreshing"
      :finished="finished"
      @onLoad="onLoad"
      :is-higeht-max="true"
      :disabled="true"
      :isNoData="isNoData"
    >
      <VideoList ref="videoList" :data-list="list" :isEdit="isEdit" @delItem="delItem" />
    </PullRefresh>
  </div>
</template>
<script>
import VideoList from '@/components/VideoList';
import { queryHistoryVideo } from '@/utils';
import PullRefresh from '@/components/PullRefresh';
import { deleteDB, openDB } from '@/utils/indexedDB';

export default {
  props: ['isEdit'],
  name: 'HistoryVideo',
  components: {
    VideoList,
    PullRefresh,
  },
  data() {
    return {
      pageNumber: 1,
      pageSize: 20,
      list: [],
      loading: true,
      refreshing: false,
      finished: false,
      isNoData: false,
      error: false,
    };
  },
  async created() {
    this.getList();
  },
  methods: {
    //获取列表
    async getList(type) {
      let list = (await queryHistoryVideo(this.pageNumber, this.pageSize, 'SP')) || [];
      if (type === 'refresh') {
        this.list = list;
      } else {
        this.list = this.list.concat(list);
      }
      this.list = this.uniqueFun(this.list, 'id');
      if (this.pageNumber == 1 && this.list.length == 0) {
        this.isNoData = true;
      }
      if (list.length < this.pageSize) {
        this.finished = true;
        return;
      }
      this.loading = false;
    },
    // 选择全部
    selectAll(isSelectAll) {
      this.$nextTick(() => {
        this.$refs?.videoList?.selectAll(isSelectAll);
      });
    },
    // 批量删除
    batchDel() {
      this.$refs.videoList.batchDel();
    },
    uniqueFun(arr, type) {
      const res = new Map();
      return arr.filter((a) => !res.has(a[type]) && res.set(a[type], 1));
    },
    async delItem(delArr) {
      let db = await openDB('rms_db');
      this.list = this.list.filter((item) => {
        let flag = delArr.find((vId) => item.id == vId);
        if (flag) {
          deleteDB(db, 'mediaInfos', flag);
        } else {
          return item;
        }
      });
      if (this.list.length == 0) return this.$emit('close');
    },
    onLoad() {
      this.loading = true;
      this.pageNumber++;
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
.history_video_warp {
  height: 100%;
  padding: 0 16px;
}
</style>
